var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',{ref:"form",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('h3',[_vm._v("About Your Annuities")]),_c('p',[_vm._v("How many annuities do you and your spouse own")]),_c('v-select',{attrs:{"solo":"","items":[
      'Please Select',
      'None',
      'I (we) own one annuity',
      'I (we) own more than one annuity' ],"rules":_vm.$store.state.annuityrules},model:{value:(_vm.$store.state.annuityCount),callback:function ($$v) {_vm.$set(_vm.$store.state, "annuityCount", $$v)},expression:"$store.state.annuityCount"}}),_c('p',[_vm._v("How long have you owned these annuities")]),_c('v-select',{attrs:{"solo":"","items":[
      'Please Select',
      'Less than 2 years',
      '2 to 7 years',
      'Over 7 years' ],"rules":_vm.$store.state.annuityrules},model:{value:(_vm.$store.state.annuityLength),callback:function ($$v) {_vm.$set(_vm.$store.state, "annuityLength", $$v)},expression:"$store.state.annuityLength"}}),_c('v-btn',{attrs:{"elevation":"2","type":"button"},on:{"click":function($event){return _vm.previous()}}},[_vm._v("Previous")]),_c('v-btn',{staticStyle:{"float":"right"},attrs:{"elevation":"2","type":"button"},on:{"click":function($event){return _vm.next()}}},[_vm._v("Next")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }