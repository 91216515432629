var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',{ref:"form",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('h3',[_vm._v("Dinner Confirmation")]),_c('v-text-field',{attrs:{"label":"Email","messages":['We will send reservation details to this email address'],"solo":""},model:{value:(_vm.$store.state.email),callback:function ($$v) {_vm.$set(_vm.$store.state, "email", $$v)},expression:"$store.state.email"}}),_c('v-text-field-simplemask',{attrs:{"label":"Mobile Phone","options":{
      inputMask: '(###) ### - ####',
      outputMask: '##########',
      empty: null,
      applyAfter: false,
      alphanumeric: false,
      lowerCase: false,
    },"properties":{
      prefix: '',
      suffix: '',
      readonly: false,
      disabled: false,
      outlined: false,
      clearable: true,
      placeholder: '',
      solo: '',
      required: '',
      messages: [
        'For Dinner confirmation only (24-48 hours prior to event) and text message' ],
      rules: [
        function (v) { return !!v || 'Please Enter your phone number'; },
        function (v) { return (v && v.length == 16) || 'Phone number should be 10 digits long'; } ],
    },"focus":_vm.focus},on:{"focus":function($event){_vm.focus = false}},model:{value:(_vm.$store.state.phone),callback:function ($$v) {_vm.$set(_vm.$store.state, "phone", $$v)},expression:"$store.state.phone"}}),_c('v-btn',{attrs:{"elevation":"2","type":"button"},on:{"click":function($event){return _vm.previous()}}},[_vm._v("Previous")]),_c('v-btn',{staticClass:"locate_btn",attrs:{"disabled":_vm.loading,"loading":_vm.loading,"dark":"","elevation":"2","id":"locate_btn"},on:{"click":function($event){return _vm.submit_form()}}},[_vm._v("Register")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }