<template>
  <v-form ref="form" v-model="valid">
    <h3>Dinner Confirmation</h3>


    <v-text-field
      label="Email"
      v-model="$store.state.email"
      :messages="['We will send reservation details to this email address']"
      solo
    ></v-text-field>
    <v-text-field-simplemask
      label="Mobile Phone"
      v-model="$store.state.phone"
      v-bind:options="{
        inputMask: '(###) ### - ####',
        outputMask: '##########',
        empty: null,
        applyAfter: false,
        alphanumeric: false,
        lowerCase: false,
      }"
      v-bind:properties="{
        prefix: '',
        suffix: '',
        readonly: false,
        disabled: false,
        outlined: false,
        clearable: true,
        placeholder: '',
        solo: '',
        required: '',
        messages: [
          'For Dinner confirmation only (24-48 hours prior to event) and text message',
        ],
        rules: [
          (v) => !!v || 'Please Enter your phone number',
          (v) =>
            (v && v.length == 16) || 'Phone number should be 10 digits long',
        ],
      }"
      v-bind:focus="focus"
      v-on:focus="focus = false"
    ></v-text-field-simplemask>

    <v-btn elevation="2" type="button" @click="previous()">Previous</v-btn>
    <v-btn
    :disabled="loading"
    :loading="loading"
    dark
    elevation="2"
    class="locate_btn"
    id="locate_btn"
    @click="submit_form()"
    >Register</v-btn
    >

  </v-form>
</template>

<style scoped>
.locate_btn {
  background-color: rgb(208, 2, 27) !important;
  color: white !important;
  padding: 30px !important;
  font-weight: bold;
  font-size: 20px !important;
}

#locate_btn {

  background-color: rgb(208, 2, 27) !important;
  color: white !important;
  padding: 30px !important;
  font-weight: bold;
  font-size: 20px !important;
  float:right;
}

#locate_btn:disabled {
  background-color: rgb(208, 2, 27) !important;
  color: white !important;
  padding: 30px !important;
  font-weight: bold;
  font-size: 20px !important;
  margin-top: -15px;
}

@media screen and (max-width: 768px) {
  .v-card {
    width: 100%;
  }
}
</style>

<script>
export default {
  name: "StepThree",

  mounted: function () {},
  methods: {
    previous() {
      this.$store.commit("setStep", 2);
    },
    async submit_form(){
      if (this.$refs.form.validate()) {
        this.loading = true;
        if (this.$store.state.annuityCount == "None" || this.$store.state.annuityLength == "Less than 2 years") {
          this.deletePromo();
          this.loading = false;
          this.$router.push("/sorry");
        } else {
          await this.addFullHousehold();
          this.loading = false;
          this.$router.push("/thankyou");
        }
      } else {
        this.$store.commit("setInvalidDialog", true);
      }
    },
  },

  data: () => ({
    valid: false,
    focus: false,
    loading: false,
  }),
};
</script>
