import Vue from "vue";
import Vuex from "vuex";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
dayjs.extend(advancedFormat);

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    // Everywhere
    seminar: null,
    household: {
      firstName: null,
      lastName: null,
      age: null,
      promoCode: null,
      street: null,
      city: null,
      state: null,
      zip: null,
    },
    has_promo: false,
    promo_code: "",

    step: 1,
    invalid_dialog: false,
    leadtype: "",
    
    // Page 1
    overflow: false,
    nightAttending: null,
    spouseName: null,
    spouseAttending: "Please Select",
    available_nights: [],

    // Page 2
    annuityCount: "Please Select",
    // annuityAmount: "Please Select",
    annuityLength: "Please Select",

    // Page 3
    email: null,
    phone: null,

    // Not really state, but globally available
    notBlankRules: [(v) => !!v || "Field is required"],
    annuityrules: [(v) => v != "Please Select" || "Please Select Option"],
    yesnorules: [(v) => v == "Yes" || v == "No" || "Please Select Option"],
    yes_no: ["Please Select", "Yes", "No"],
  },
  mutations: {
    setAddress(state, payload) {
      state.household.street = payload.name;
      state.household.city = payload.locality;
      state.household.state = payload.administrative_area_level_1;
      state.household.zip = payload.postal_code;
    },
    setStep(state, payload) {
      state.step = payload;
    },
    setInvalidDialog(state, payload) {
      state.invalid_dialog = payload;
    },
    setSeminar(state, payload) {
      console.log(payload);
      state.seminar = payload;
    },
    setPromo(state, payload) {
      state.promo_code = payload;
    },
    setHasPromo(state, payload) {
      state.has_promo = payload;
      if (payload) {
        state.leadtype = "mail";
      } else {
        state.leadtype = "weblink";
      }
    },
    setHousehold(state, payload) {
      state.household = payload;
    },
    setNewHousehold(state) {
      state.household = {
        firstName: null,
        lastName: null,
        age: null,
        promoCode: null,
        street: null,
        city: null,
        state: null,
        zip: null,
      };
    },
    setNightsAvailable(state) {
      if (state.seminar) {
        state.available_nights = [];
        state.overflow = false;
        let datefmt = (date, time) => {
          return dayjs(date).format("dddd, MMMM Do") + " at " + time;
        };

        state.seminar.events.edges.sort(function(a, b) {
          return new Date(a.node.startDate) < new Date(b.node.startDate)
            ? -1
            : 1;
        });

        state.seminar.events.edges.forEach((element) => {
          let disabled = element.node.realCount >= element.node.capacity;
          let d = new Date(element.node.startDate);
          if (d < new Date()) {
            disabled = true;
          }
          let value = datefmt(element.node.startDate, element.node.startTime);
          if (disabled) {
            value += " (full)";
          }
          state.available_nights.push({
            value: value,
            disabled: disabled,
          });
        });

        if (
          state.available_nights.filter(function(i) {
            return !i.disabled;
          }).length === 0
        ) {
          state.overflow = true;
          state.available_nights.push({
            value: "Standby List; we will contact you regarding availablity",
            disabled: false,
          });
          state.nightAttending = state.available_nights.length - 1;
        }

        if (state.available_nights.length === 1) {
          state.nightAttending = 0;
        }
      }
    },
  },
  actions: {},
  modules: {},
});
