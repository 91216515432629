<template>
  <v-layout justify-center>
    <v-card
      elevation="3"
      max-width="600"
      style="
        background-color: rgb(235, 236, 243) !important;
        padding: 10px;
        padding-left: 20px;
        padding-right: 20px;
      "
    >
      <v-form ref="form" v-model="valid">
        <h2 style="text-align: center">
          Register Below for your {{ $store.state.seminar.venueName }} Dinner Event
        </h2>

        <v-row>
          <v-col>
            <step-one v-if="$store.state.step == 1"></step-one>
            <step-two v-if="$store.state.step == 2"></step-two>
            <step-three v-if="$store.state.step == 3"></step-three>
          </v-col>
        </v-row>
        

        <br />
        <p style="font-weight: light; font-size: 12px">
          Disclaimer: Attendance is restricted to recipients over the age of 55
          that are named on the mailer and his/her spouse. The workshop may only
          be attended once during any 12-month period. Seating is Extremely
          Limited. As a courtesy, final confirmation calls will be made 24-48
          hours before the event. No agents or bankers will be admitted.
        </p>
      </v-form>
    </v-card>

    <v-dialog v-model="$store.state.invalid_dialog" width="500">
      <v-card>
        <v-card-title class="headline primary" style="color: white">
          Please fill out all required fields.
        </v-card-title>

        <v-card-text>
          <br />
          Please double check your entry and try again. If you believe it is
          correct, please contact
          <a href="mailto:support@annuitycheck.com">support@annuitycheck.com</a
          >.
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="$store.state.invalid_dialog = false"> Okay </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<style scoped>

@media screen and (max-width: 768px) {
  .v-card {
    width: 100%;
  }
}
</style>

<script>
import StepOne from "../components/StepOne.vue"
import StepTwo from "../components/StepTwo.vue"
import StepThree from "../components/StepThree.vue"

export default {
  components: { StepOne, StepTwo, StepThree },
  name: "MainForm",

  mounted: function () {
    // this.deletePromo()
  },
  methods: {
  },
  data: () => ({
    valid: false,
  }),
};
</script>
