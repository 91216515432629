<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" class="head">
        <img class="backgroundPhoto" src="../assets/background.jpg" />

        <div class="textContent">
          <!-- <v-row> -->
          <!-- col-xl-3 col-xl-offset-9 col-md-5 col-sm-10 offset-md-6 offset-sm-1  -->
          <v-col
            class="header_bg"
            v-if="this.found !== true && this.loading === false"
          >
            <br />

            <v-row
              justify="center"
              v-if="this.found !== true && this.loading === false"
            >
              <p class="promo_msg">
                Invalid Promo Code! Please contact the person who gave you this
                link for assistance.
              </p>
            </v-row>
            <v-row justify="center" v-if="this.loading === true">
              <!-- WE dont need this atm, if we get the the point where loading takes +1 second, we can use it -->
              <v-progress-circular
                :size="70"
                :width="7"
                color="rgb(208, 2, 27)"
                indeterminate
              ></v-progress-circular>
            </v-row>
            <br />
          </v-col>
          <!-- </v-row> -->
          <v-spacer />
          <br />

          <v-col>
            <!-- <a name="form" id="form" class="form"> -->
              <main-form
                v-if="this.found == true"
              ></main-form>
            <!-- </a> -->
          </v-col>
        </div>

        <!-- </v-img> -->
      </v-col>
    </v-row>
  </v-container>
</template>

<style scoped>
.container {
  padding-top: 0px;
}
.head {
  padding: 0px;
}

.thankYouDiv {
  padding: 20px;
  max-width: 600px;
  position: relative;
  margin: auto;
  background-color: rgba(0.3, 0.3, 0.3, 0.8);
  margin-top: 100px;
}

.backgroundPhoto {
  position: fixed;
  top: 0;
  height: 100%;
  /* filter: blur(5px);
  -webkit-filter: blur(5px); */
}

.textContent {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 100;
}

.v-input input {
  background-color: white;
  color: black;
}

.promo_msg {
  text-align: center;
  color: white;
  margin-top: 30px;
  margin-left: 40px;
  margin-right: 40px;
  padding-left: 40px;
  padding-right: 40px;
  font-style: italic;
  font-size: 18px;
}

.headline {
  color: white;
  text-align: center;
}

.bgimg {
  background-position: top;
}
.header_bg {
  background-color: rgba(0.3, 0.3, 0.3, 0.8);
  border-radius: 5px;
  padding: 15px;
  max-width: 600px;
  position: relative;
  margin: auto;
  margin-top: 100px;
  margin-bottom: 30px;
}

#locate_btn {
  background-color: rgb(208, 2, 27) !important;
  color: white !important;
  padding: 30px !important;
  font-weight: bold;
  font-size: 20px !important;
  margin-top: -15px;
}

#locate_btn:disabled {
  background-color: rgb(208, 2, 27) !important;
  color: white !important;
  padding: 30px !important;
  font-weight: bold;
  font-size: 20px !important;
  margin-top: -15px;
}

@media screen and (max-width: 768px) {
  .header_bg {
    margin: 0;
    max-width: 100%;
    width: 100%;
  }
  .thankYouDiv {
    margin-top: 0;
  }
}
</style>

<script>
// import MARKETED_PERSON_BY_PROMO_CODE from "../graphql/MarketedPersonByPromoCode.gql";
import CAMPAIGN_BY_ID from "../graphql/CampaignByID.gql";
import MainForm from "../components/MainForm";

export default {
  name: "Mailer",
  components: {
    MainForm,
  },
  mounted: async function() {
    let result = await this.$apollo.query({
      query: CAMPAIGN_BY_ID,
      variables: { id: +this.$route.params.id },
    });

    let seminar = result.data.campaign;
    this.$store.commit("setSeminar", seminar);
    this.$store.commit("setNewHousehold");
    this.$store.commit("setHasPromo", false);
    this.$store.commit("setNightsAvailable");


    console.log(seminar);
    if (seminar) {
      this.found = true;
      this.loading = false;
    } else {
      this.found = false;
      this.loading = false;
    }
  },

  data: () => ({
    loading: true,
    found: false,
  }),
};
</script>
