import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
dayjs.extend(advancedFormat);
import Vue from "vue";
import CREATE_HOUSEHOLD from "../graphql/CreateHousehold.gql";
import CREATE_PEOPLE from "../graphql/CreatePeople.gql";
import SEND_TEMPLATE_EMAIL from "../graphql/SendTemplateEmail.gql";
const axios = require("axios").default;

Vue.mixin({
  methods: {
    datefmt(date, time) {
      return dayjs(date).format("dddd, MMMM Do") + " at " + time;
    },
    async deletePromo() {
      let notes = `Number of Annuities: ${this.$store.state.annuityCount} || How Long: ${this.$store.state.annuityLength}`;

      let data = {
        age: this.$store.state.household.age,
        name:
          this.$store.state.household.firstName +
          " " +
          this.$store.state.household.lastName,
        campaignId: this.$store.state.seminar.dbId,
        city: this.$store.state.household.city,
        email: this.$store.state.email,
        leadType: this.$store.state.leadtype,
        notes: notes,
        phone: this.$store.state.phone,
        promoCode: this.$store.state.household.promoCode,
        state: this.$store.state.household.state,
        street: this.$store.state.household.street,
        userId: this.$store.state.seminar.userId,
        zip: this.$store.state.household.zip,
      }


      let _url = "https://hooks.zapier.com/hooks/catch/2010897/bu4v5pn/";
      try {
        var xhr = new XMLHttpRequest();
        xhr.open("POST", _url);
        xhr.send(JSON.stringify(data));
        console.log("Pushed to Zapier successfully!");
      } catch(e) {
        console.error(e);
      }

      // axios.post(, , })
      // .then((result) => {
      //   console.log(result);
      // })
      // .catch((err) => {
      //   console.log(err);
      // });


      if (this.$store.state.has_promo) {
        const promo_code = this.$store.state.promo_code;
        await axios.get(
          `https://api-v3.annuitycheck.com/misc/delete_promo/${promo_code}`
        );
      }
    },

    async addFullHousehold() {
      // Validate and create household or delete promo code
      console.log(this.$store.state);

      let notes = `Number of Annuities: ${this.$store.state.annuityCount} || How Long: ${this.$store.state.annuityLength}`;
      let prettyDate = this.datefmt(
        this.$store.state.seminar.events.edges[this.$store.state.nightAttending]
          .node.startDate,
        this.$store.state.seminar.events.edges[this.$store.state.nightAttending]
          .node.startTime
      );

      let result = await this.$apollo.mutate({
        mutation: CREATE_HOUSEHOLD,
        variables: {
          campaignId: this.$store.state.seminar.dbId,
          city: this.$store.state.household.city,
          email: this.$store.state.email,
          eventId: this.$store.state.overflow
            ? null
            : this.$store.state.seminar.events.edges[
                this.$store.state.nightAttending
              ].node.dbId,
          leadType: this.$store.state.leadtype,
          notes: notes,
          phone: this.$store.state.phone,
          promoCode: this.$store.state.household.promoCode,
          state: this.$store.state.household.state,
          status: this.$store.state.overflow ? "overflow" : "registered",
          street: this.$store.state.household.street,
          userId: this.$store.state.seminar.userId,
          zip: this.$store.state.household.zip,
        },
      });
      console.log("HOUSEHOLD CREATION");
      console.log(result);

      // CREATE SPOUSE
      if (this.$store.state.spouseAttending == "Yes") {
        this.$apollo.mutate({
          mutation: CREATE_PEOPLE,
          variables: {
            age: null,
            gender: null,
            householdId: result.data.createHousehold.id,
            name: this.$store.state.spouseName,
            primary: false,
          },
        });
      }

      let final_result = await this.$apollo.mutate({
        mutation: CREATE_PEOPLE,
        variables: {
          age: this.$store.state.household.age,
          gender: null,
          householdId: result.data.createHousehold.id,
          name:
            this.$store.state.household.firstName +
            " " +
            this.$store.state.household.lastName,
          primary: true,
        },
      });

      console.log(final_result);

      // CONFIRMATION EMAIL FOR CONSUMER
      this.$apollo.mutate({
        mutation: SEND_TEMPLATE_EMAIL,
        variables: {
          templateId: "consumer-seminar-registration",
          subject: "Your Seminar Registration has been Confirmed!",
          recipientEmail: this.$store.state.email,
          recipientName:
            this.$store.state.household.firstName +
            " " +
            this.$store.state.household.lastName,
          data: JSON.stringify({
            venue_name: this.$store.state.seminar.venueName,
            venue_location:
              this.$store.state.seminar.street +
              ", " +
              this.$store.state.seminar.city +
              ", " +
              this.$store.state.seminar.state +
              ", " +
              this.$store.state.seminar.zip,
            time: this.$store.state.overflow ? "Overflow" : prettyDate,
          }),
        },
      });

      if (!this.$store.state.overflow) {
        axios
          .post("https://api-v3.annuitycheck.com/misc/sms", {
            to: this.$store.state.phone,
            message: `Hi ${this.$store.state.household.firstName}, thank you for registering for our workshop on ${prettyDate}.  DO NOT REPLY.`,
          })
          .then(function(response) {
            console.log(response);
          })
          .catch(function(error) {
            console.log(error);
          });
      }

      // Instant update cache count
      axios.get(
        `https://api-v3.annuitycheck.com/misc/update_cache_count`
      );

      // CONFIRMATION EMAIL FOR AGENT
      this.$apollo.mutate({
        mutation: SEND_TEMPLATE_EMAIL,
        variables: {
          templateId: "agent-seminar-registration",
          subject:
            this.$store.state.household.firstName +
            " " +
            this.$store.state.household.lastName +
            " has just Registered for your Seminar!",
          recipientEmail: this.$store.state.seminar.user.email,
          recipientName:
            this.$store.state.seminar.user.firstName +
            " " +
            this.$store.state.seminar.user.lastName,
          data: JSON.stringify({
            venue_name: this.$store.state.seminar.venueName,
            name:
              this.$store.state.household.firstName +
              " " +
              this.$store.state.household.lastName,
            venue_location:
              this.$store.state.household.street +
              ", " +
              this.$store.state.household.city +
              ", " +
              this.$store.state.household.state +
              ", " +
              this.$store.state.household.zip,
            phone: this.$store.state.phone,
            time: this.$store.state.overflow ? "Overflow" : prettyDate,
          }),
        },
      });
    },
  },
});
