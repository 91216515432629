<template>
  <v-form ref="form" v-model="valid">
    <h3>About Your Annuities</h3>
    <p>How many annuities do you and your spouse own</p>
    <v-select
      solo
      :items="[
        'Please Select',
        'None',
        'I (we) own one annuity',
        'I (we) own more than one annuity',
      ]"
      :rules="$store.state.annuityrules"
      v-model="$store.state.annuityCount"
    >
    </v-select>
    <p>How long have you owned these annuities</p>
    <v-select
      solo
      :items="[
        'Please Select',
        'Less than 2 years',
        '2 to 7 years',
        'Over 7 years',
      ]"
      :rules="$store.state.annuityrules"
      v-model="$store.state.annuityLength"
    >
    </v-select>
    <!-- <p>Combined value of your annuities</p>
    <v-select
      solo
      :items="[
        'Please Select',
        'Less than $30,000',
        '$30,000 to $100,000',
        'More than $100,000',
      ]"
      :rules="$store.state.annuityrules"
      v-model="$store.state.annuityAmount"
    >
    </v-select> -->


    <v-btn elevation="2" type="button" @click="previous()">Previous</v-btn>
    <v-btn elevation="2" type="button" @click="next()" style="float: right">Next</v-btn>

  </v-form>
</template>

<style scoped>
.locate_btn {
  background-color: rgb(208, 2, 27) !important;
  color: white !important;
  padding: 30px !important;
  font-weight: bold;
  font-size: 20px !important;
  margin-top: -15px;
}

#locate_btn {
  background-color: rgb(208, 2, 27) !important;
  color: white !important;
  padding: 30px !important;
  font-weight: bold;
  font-size: 20px !important;
  margin-top: -15px;
}

#locate_btn:disabled {
  background-color: rgb(208, 2, 27) !important;
  color: white !important;
  padding: 30px !important;
  font-weight: bold;
  font-size: 20px !important;
  margin-top: -15px;
}

@media screen and (max-width: 768px) {
  .v-card {
    width: 100%;
  }
}
</style>

<script>
export default {
  name: "StepTwo",

  mounted: function () {},
  methods: {
    next() {
      if (this.$refs.form.validate()) {
        this.$store.commit("setStep", 3);
      } else {
        this.$store.commit("setInvalidDialog", true);
      }
    },
    previous() {
      this.$store.commit("setStep", 1);
    },
  },

  data: () => ({
    valid: false,
  }),
};
</script>
