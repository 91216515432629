<template>
  <v-form ref="form" v-model="valid">
    <h3>About Your Reservation</h3>
    <p>What night will you be attending?</p>
    <v-radio-group
      v-model="$store.state.nightAttending"
      required
      :rules="[(v) => v !== null || 'Please Select a night']"
    >
      <v-radio
        v-for="n in $store.state.available_nights.length"
        :disabled="$store.state.available_nights[n - 1].disabled"
        :key="n"
        :label="$store.state.available_nights[n - 1].value"
        :value="n - 1"
      ></v-radio>
    </v-radio-group>

    <h3 v-if="$store.state.has_promo">
      Primary Registrant:
      {{ $store.state.household.firstName }}
      {{ $store.state.household.lastName }}
    </h3>

    <p v-if="!$store.state.has_promo">Your Name</p>
    <v-text-field
      :rules="$store.state.notBlankRules"
      v-if="!$store.state.has_promo"
      label="First and last name"
      v-model="$store.state.household.firstName"
      required
      solo
    ></v-text-field>
    <p v-if="!$store.state.has_promo">Your Address</p>
    <vuetify-google-autocomplete
      v-if="!$store.state.has_promo"
      id="map"
      append-icon="mdi-magnify"
      solo
      placeholder="Address"
      v-on:placechanged="getAddressData"
    >
    </vuetify-google-autocomplete>

    <p>Will your spouse or a guest be attending</p>
    <v-select
      solo
      :items="$store.state.yes_no"
      :rules="$store.state.yesnorules"
      v-model="$store.state.spouseAttending"
    >
    </v-select>

    <v-text-field
      :rules="$store.state.notBlankRules"
      v-if="$store.state.spouseAttending === 'Yes'"
      label="First and last name of spouse or guest"
      v-model="$store.state.spouseName"
      required
      solo
    ></v-text-field>

    <v-btn elevation="2" type="button" @click="next()" style="float:right">Next</v-btn>

  </v-form>
</template>

<style scoped>
.locate_btn {
  background-color: rgb(208, 2, 27) !important;
  color: white !important;
  padding: 30px !important;
  font-weight: bold;
  font-size: 20px !important;
  margin-top: -15px;
}

#locate_btn {
  background-color: rgb(208, 2, 27) !important;
  color: white !important;
  padding: 30px !important;
  font-weight: bold;
  font-size: 20px !important;
  margin-top: -15px;
}

#locate_btn:disabled {
  background-color: rgb(208, 2, 27) !important;
  color: white !important;
  padding: 30px !important;
  font-weight: bold;
  font-size: 20px !important;
  margin-top: -15px;
}

@media screen and (max-width: 768px) {
  .v-card {
    width: 100%;
  }
}
</style>

<script>
export default {
  name: "StepOne",

  mounted: function () {

  },
  methods: {
    // eslint-disable-next-line no-unused-vars
    getAddressData: function (addressData, placeResultData, id) {
      this.$store.commit("setAddress", addressData);
    },

    next() {
        if (this.$refs.form.validate()) {
            this.$store.commit("setStep", 2);
        } else {
            this.$store.commit("setInvalidDialog", true);
        }
    },
  },

  data: () => ({
      valid: false,
  }),
};
</script>
